import React from "react";
import Img from "gatsby-image";
import { getFluidGatsbyImage } from "gatsby-source-sanity";
import clientConfig from "../../client-config";

export default ({ node }) => {
  // if (!node || !node.video.asset|| !node.video._id || !node.image.asset || !node.image.asset._id) {
  //   return null;
  // }
  if (node.image && node.image.asset) {
    const fluidProps = getFluidGatsbyImage(
      node.image.asset._id,
      { maxWidth: 3200 },
      clientConfig.sanity
    );
    return <Img fluid={fluidProps} alt={node.alt} />;
  } else if (node.video && node.video.asset) {
    return (
      <video autoPlay loop muted playsInline className='animated-video-block'>
        <source src={node.video.asset.url} type="video/mp4" />
      </video>
    );
    
  }
  
};
