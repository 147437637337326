import React from 'react';
import Figure from './Figure';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import InstagramPost from './InstagramPost';

const serializers = {
  marks: {
    internalLink: ({mark, children}) => {
      const {slug = {}} = mark.item
      const href = `/${slug.current}`
      if(mark._type === "internalLink" && mark.item._type === "work") {
        return (
          <a href={"/work" + href}>{children}</a>
        )
      } else {
        return <a href={href}>{children}</a>
      }
    },
    link: ({mark, children}) => {
      const { blank, href } = mark
      return blank ?
        <a href={href} target="_blank" rel="noopener">{children}</a>
        : <a href={href}>{children}</a>
    },
  },
  types: {
    teamReference: ({ node }) => <span>{node.team.name}</span>,
    media: Figure,
    code: ({node}) => ReactHtmlParser(node.code),
    instagramPost: ({node}) => <InstagramPost node={node} />
  }
};

export default serializers;
