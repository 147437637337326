import React from 'react';
import InstagramEmbed from "react-instagram-embed";

export default ({ node }) => {
    return (
        <InstagramEmbed
            clientAccessToken='450759649845707|a60833209c1d59afbaf14365618babb5'
            url={node.url}
            maxWidth={480}
            containerTagName="div"
            injectScript
        />
    )
}
